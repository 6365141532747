import styled, { css } from 'styled-components'

const title = css`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`

const TitleH1 = styled.h1`
  ${title}
`

const TitleH2 = styled.h2`
  ${title}
`

const TitleH3 = styled.h3`
  ${title}
`

const TitleH4 = styled.h4`
  ${title}
`

const TitleH5 = styled.h5`
  ${title}
`

const TitleH6 = styled.h6`
  ${title}
`

export default Object.assign(TitleH1, {
  h1: TitleH1,
  h2: TitleH2,
  h3: TitleH3,
  h4: TitleH4,
  h5: TitleH5,
  h6: TitleH6,
})
