import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IFaq } from '../hooks/useFaqQuery'
import markdownToHtml from '../utils/markdownToHtml'
import htmlToReact from '../utils/htmlToReact'

const iconSize = '12px'

const Details = styled.details`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  summary {
    position: relative;
    list-style: none;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: ${({ theme }) => theme.colors.body};
    padding-right: calc(12px + 10px);
    padding: 7px calc(14px + ${iconSize}) 7px 14px;
  }

  &[open] summary {
    font-style: normal;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
  }

  summary::-webkit-details-marker {
    display: none;
  }

  & summary::after {
    content: ' ';
    background-image: url('/images/DetailsMore.svg');
    display: inline-block;
    position: absolute;
    top: 8px;
    right: 8px;
    width: ${iconSize};
    height: ${iconSize};
    background-repeat: no-repeat;
    background-position: center center;
  }

  &[open] summary::after {
    background-image: url('/images/DetailsLess.svg');
  }
`

const Content = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.body};
  padding: 8px 14px 13px 14px;

  & a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

interface FaqItemProps {
  item: IFaq
}

const FaqItem: React.FC<FaqItemProps> = ({ item }) => {
  const answer = useMemo(
    () => htmlToReact(markdownToHtml(item.answer)),
    [item.answer]
  )

  return (
    <Details>
      <summary>{item.question}</summary>
      <Content>{answer}</Content>
    </Details>
  )
}

export default FaqItem
