import { PageProps } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import FaqContainer from '../../components/FaqContainer'
import FaqFooter from '../../components/FaqFooter'
import FaqList from '../../components/FaqList'
import { LayoutProps } from '../../components/Layout.context'
import { contactLink } from '../../data/menu'
import { aboutLink } from '../../data/menu.context'
import useFaqQuery from '../../hooks/useFaqQuery'

const FaqPage: React.FC<PageProps> = () => {
  const faq = useFaqQuery()

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="Comment obtenir un bon de réparation ?"
          content="Consultez la FAQ de Save.co pour obtenir des réponses aux questions les plus fréquentes sur nos
          services de réparation d'appareils, protection et récupération de données, ainsi que la vente d'appareils
          reconditionnés. Trouvez rapidement les informations dont vous avez besoin."
        />
      </Helmet>

      <FaqContainer>
        <FaqList>
          <FaqList.Title>Une question ? Une réponse !</FaqList.Title>
          <FaqList.Items items={faq} />
        </FaqList>

        <FaqContainer.Divider />

        <FaqFooter>
          <FaqFooter.Title>Une autre question ?</FaqFooter.Title>
          <FaqFooter.Paragraph>
            Si vous ne trouvez pas la réponse à votre question n'hésitez pas à
            passer par{' '}
            <FaqFooter.Link to={contactLink}>notre page contact</FaqFooter.Link>{' '}
            et à détailler votre question dans le formulaire à disposition.
          </FaqFooter.Paragraph>
        </FaqFooter>
      </FaqContainer>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Questions fréquentes et réponses - FAQ',
  breadcrumb: [{ label: 'À propos', href: aboutLink }, { label: 'FAQ' }]
}

export default Object.assign(FaqPage, {
  layoutProps,
})
