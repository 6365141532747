import { useStaticQuery, graphql } from 'gatsby'
import { FaqQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'

export type IFaq = NonNullable<
  Unpacked<NonNullable<FaqQuery['strapi']['faqs']>>
>

const useFaqQuery = (): IFaq[] => {
  const { strapi }: FaqQuery = useStaticQuery(
    graphql`
      query Faq {
        strapi {
          faqs(sort: "order:asc") {
            id
            question
            answer
          }
        }
      }
    `
  )
  return strapi.faqs?.filter(notEmpty) ?? []
}

export default useFaqQuery
